export function buildQueryString(fields: Record<string, string | number>) {
  const arr: string[] = [];
  Object.keys(fields).forEach((key) => {
    if (fields[key] && fields[key] !== '0') {
      arr.push(`${key}=${fields[key]}`.replace(/[\s,]/g, ''));
    }
  });

  if (arr.length) {
    return `?${arr.join('&')}`;
  }
  return '';
}
