import {
  DEFAULT_TP_GRAY,
  starRatingColorMap,
  TTrustPilotStars,
} from './trust-pilot-stars.types';
import { TrustPilotStar } from './trust-pilot-star';

export const TrustPilotStars = ({
  starRating,
  size = 48,
}: TTrustPilotStars) => {
  const generateTrustPilotStars = (starRating = 5, size = 48) => {
    const fullStars = Math.floor(starRating);
    const hasHalfStar = starRating % 1 !== 0;
    const starColor = starRatingColorMap.get(starRating) || DEFAULT_TP_GRAY;
    const totalStars = 5;

    return Array.from({ length: totalStars }).map((_, index) => {
      const isHalfStar = index === fullStars && hasHalfStar;
      const color =
        index < fullStars || isHalfStar ? starColor : DEFAULT_TP_GRAY;

      return (
        <TrustPilotStar
          color={color}
          size={size}
          isHalfStar={isHalfStar}
          key={`${index}-tp-star`}
        />
      );
    });
  };

  return (
    <div className="flex flex-row gap-[2px]">
      {generateTrustPilotStars(starRating, size)}
    </div>
  );
};
