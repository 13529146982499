import React from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';

import { cn } from '@pbx/shared/utilities/combine-class-names';
import { textVariants } from '@pbx/shared/ui/common/text';

import { TButtonProps } from './button.types';
import { buttonVariants, iconRightVariant } from './button.variants';

export const Button = React.forwardRef<HTMLButtonElement, TButtonProps>(
  (
    {
      className,
      variant = 'primary',
      asChild = false,
      inverted = false,
      size,
      icon,
      iconPosition = 'left',
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, inverted }),
          textVariants({ variant: 'ui', size: 'medium-500' }),
          className
        )}
        ref={ref}
        {...props}
      >
        {icon && iconPosition === 'left' && (
          <span className="mr-2">{icon}</span>
        )}
        <Slottable>{children}</Slottable>
        {icon && iconPosition === 'right' && (
          <span className={iconRightVariant({ variant })}>{icon}</span>
        )}
      </Comp>
    );
  }
);
Button.displayName = 'Button';
