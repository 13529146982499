// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';
import React from 'react';

import { TLinkProps } from './link.types';

export function Link({
  href = '',
  newTab = false,
  className,
  children,
}: React.PropsWithChildren<TLinkProps>) {
  return (
    <NextLink
      href={href}
      {...(newTab && {
        target: '_blank',
        rel: 'noopener noreferrer',
      })}
      className={className}
    >
      {children}
    </NextLink>
  );
}
