import {
  useQuery as useQueryTanstack,
  UseQueryOptions,
  useQueries as useQueriesTanstack,
} from '@tanstack/react-query';

export function useQuery<TData>(props: UseQueryOptions<TData>) {
  return useQueryTanstack(props);
}

export function useQueries(props: { queries: UseQueryOptions[] }) {
  return useQueriesTanstack(props);
}
