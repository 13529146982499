import { cva } from 'class-variance-authority';

export const buttonVariants = cva(
  'relative inline-flex items-center justify-center rounded-small transition-colors hover:underline focus-visible:border-focus focus:outline-none disabled:pointer-events-none py-3 px-11 min-w-[168px] max-w-full',
  {
    variants: {
      variant: {
        primary:
          'text-button-primary-foreground bg-button-primary-background hover:bg-button-primary-background-hover hover:text-button-primary-foreground-hover',
        secondary:
          'text-button-secondary-foreground bg-button-secondary-background hover:bg-button-secondary-background-hover hover:text-button-secondary-foreground-hover',
        ghost:
          'text-button-tertiary-foreground border-button-tertiary-foreground border border-solid disabled:border-border-disabled',
        tertiary:
          'min-w-max text-button-tertiary-foreground bg-button-tertiary-background hover:text-button-tertiary-foreground-hover disabled:bg-transparent px-0',
      },
      inverted: {
        true: 'disabled:bg-brand-background-disabled disabled:text-brand-foreground-disabled',
        false:
          'disabled:bg-background-disabled disabled:text-foreground-disabled',
      },
      size: {
        icon: 'min-w-min [&>*]:m-0 px-3',
        small: 'px-3',
      },
    },
    compoundVariants: [
      {
        variant: 'primary',
        inverted: true,
        class:
          'text-brand-button-primary-foreground bg-brand-button-primary-background hover:bg-brand-button-primary-background-hover hover:text-brand-button-primary-foreground-hover',
      },
      {
        variant: 'secondary',
        inverted: true,
        class:
          'text-brand-button-secondary-foreground bg-brand-button-secondary-background hover:bg-brand-button-secondary-background-hover hover:text-brand-button-secondary-foreground-hover',
      },
      {
        variant: 'ghost',
        inverted: true,
        class:
          'text-brand-button-tertiary-foreground bg-brand-button-tertiary-background border-brand-button-tertiary-foreground border border-solid disabled:border-none',
      },
      {
        variant: 'tertiary',
        inverted: true,
        class:
          'text-brand-button-tertiary-foreground bg-brand-button-tertiary-background hover:bg-brand-button-tertiary-background-hover hover:text-brand-button-tertiary-foreground-hover disabled:bg-transparent px-0',
      },
      {
        variant: 'tertiary',
        inverted: false,
        class: 'disabled:bg-transparent',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      inverted: false,
    },
  }
);

export const iconRightVariant = cva('', {
  variants: {
    variant: {
      primary: 'absolute right-3',
      secondary: 'absolute right-3',
      ghost: 'absolute right-3',
      tertiary: 'ml-2',
    },
  },
});
