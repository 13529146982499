import { buildQueryString } from '@pbx/shared/utilities/build-query-string';

export function buildGetReviewsQuery(
  stars: number[],
  pageSize?: number,
  nextPageToken?: string
) {
  const starsQuery = stars.map((star) => `Stars=${star}`).join('&');

  const queryParams: Record<string, string> = {
    PageSize: pageSize?.toString() || '',
    NextPageToken: nextPageToken || '',
  };

  const query = buildQueryString(queryParams);

  if (starsQuery && query) {
    return `${starsQuery}&${query.slice(1)}`;
  }
  if (starsQuery) {
    return starsQuery;
  }
  return query;
}
