export const DEFAULT_TP_GRAY = '#D3D3D3';

export type TTrustPilotStar = {
  color: string;
  size: number;
  isHalfStar?: boolean;
};

export type TTrustPilotStars = {
  starRating: number;
  size: number;
};

export const starRatingColorMap = new Map([
  [0, DEFAULT_TP_GRAY],
  [1, '#FF3722'],
  [1.5, '#FF3722'],
  [2, '#FF8622'],
  [2.5, '#FF8622'],
  [3, '#FFCE00'],
  [3.5, '#FFCE00'],
  [4, '#73CF11'],
  [4.5, '#00B67A'],
  [5, '#00B67A'],
]);
