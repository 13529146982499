'use client';
import NextImage, { ImageLoaderProps } from 'next/image';

import { addHttpsToUrl } from '@pbx/shared/utilities/add-https-to-url';
import { cn } from '@pbx/shared/utilities/combine-class-names';

import { TImageProps } from './image.types';
import { imageVariants } from './image.variants';

// Custom image loader function to construct the URL with appropriate parameters
const imageLoader = ({ src, width, quality }: ImageLoaderProps): string => {
  return `${addHttpsToUrl(src)}?fm=webp&w=${width}&q=${quality || 70}`;
};

export function Image({
  src,
  width,
  height,
  alt,
  className,
  responsive = false,
  preload = false,
  sizes,
  ...rest
}: TImageProps) {
  const imageSizes = sizes || '100vw';

  return (
    <NextImage
      loader={imageLoader}
      src={src}
      width={width}
      height={height}
      alt={alt}
      sizes={imageSizes}
      className={cn(imageVariants({ responsive }), className)}
      {...(preload ? { priority: true } : { loading: 'lazy' })}
      {...rest}
    />
  );
}
