import { fetchApi, TUseQueryOptions } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import { TTrustPilotScore } from '../reviews.types';
import { GET_MARKETING_TRUST_PILOT_SCORES } from '../reviews.constants';

export async function getMarketingTrustPilotScore() {
  return await fetchApi<TTrustPilotScore>(
    `${process.env.NEXT_PUBLIC_MARKETING_REVIEWS_API_URL}/Score/provider/trustpilot`,
    {
      mode: 'cors',
    }
  );
}

export function useGetMarketingTrustPilotScore(
  config: TUseQueryOptions<TTrustPilotScore> = {}
) {
  return useQuery({
    queryKey: [GET_MARKETING_TRUST_PILOT_SCORES],
    queryFn: () => getMarketingTrustPilotScore(),
    ...config,
  });
}
