import {
  requestInterceptor,
  requestInterceptorError,
  responseInterceptor,
} from './interceptors/interceptors';
import { isReactNative } from './api-core.helpers';
import { TFetchConfig } from './api-core.types';

export async function fetchInstance<TData = unknown, TBody = unknown>(
  url: string,
  config: TFetchConfig<TBody> = {}
): Promise<TData> {
  try {
    const interceptedConfig = await requestInterceptor(config);

    const response = await fetch(url, interceptedConfig);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await responseInterceptor(response);

    // add catch to handle gracefully if there is no JSON response (e.g. 201 Created)
    const data = await result.json().catch(() => null);

    return data as TData;
  } catch (error) {
    await requestInterceptorError(error);

    console.error('Error fetching data:', error);
    throw error;
  }
}

export async function fetchApi<TData = unknown, TBody = unknown>(
  url: string,
  config: TFetchConfig<TBody> = {}
): Promise<TData> {
  const webConfig: TFetchConfig<TBody> = {
    platform: 'web',
  };

  const mobileConfig: TFetchConfig<TBody> = {
    platform: 'mobile',
  };

  const newConfig = isReactNative ? mobileConfig : webConfig;

  return fetchInstance<TData, TBody>(url, {
    ...newConfig,
    ...config,
  });
}
