import { DEFAULT_TP_GRAY, TTrustPilotStar } from './trust-pilot-stars.types';

export const TrustPilotStar = ({
  color = DEFAULT_TP_GRAY,
  size = 48,
  isHalfStar = false,
}: TTrustPilotStar) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    {/* Left side filled with the actual color */}
    <rect width="24" height="48" fill={color} />
    <path
      d="M24 32.35L31.2999 30.5L34.3499 39.9L24 32.35ZM40.7999 20.2H27.95L24 8.09998L20.05 20.2H7.19995L17.6 27.7L13.65 39.8L24.0499 32.3L30.45 27.7L40.7999 20.2Z"
      fill="white"
    />
    {/* Right side of the star with optional TP gray color */}
    <rect
      x="24"
      width="24"
      height="48"
      fill={isHalfStar ? DEFAULT_TP_GRAY : color}
    />
    <path
      d="M24 32.35L31.2999 30.5L34.3499 39.9L24 32.35ZM40.7999 20.2H27.95L24 8.09998L20.05 20.2H7.19995L17.6 27.7L13.65 39.8L24.0499 32.3L30.45 27.7L40.7999 20.2Z"
      fill="white"
    />
  </svg>
);
