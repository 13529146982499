// Check if the environment is Node.js
const isNode =
  typeof process !== 'undefined' &&
  process.versions != null &&
  process.versions.node != null;

// Check if the environment is Web (browser)
const isWeb = typeof window !== 'undefined' && typeof document !== 'undefined';

// Check if the environment is React Native (React Native generally doesn't have 'window' or 'document')
export const isReactNative = !isNode && !isWeb;
