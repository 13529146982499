import { fetchApi } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import {
  GetTrustPilotReviewsParams,
  TrustPilotReviews,
  UseGetTrustPilotReviews,
} from '../reviews.types';
import { GET_MORTGAGES_TRUST_PILOT_REVIEWS } from '../reviews.constants';
import { buildGetReviewsQuery } from '../helpers/query-builders';

export async function getMortgagesTrustPilotReviews({
  stars = [5, 4],
  pageSize = 25,
  nextPageToken,
}: GetTrustPilotReviewsParams) {
  const queryParams = buildGetReviewsQuery(stars, pageSize, nextPageToken);

  return await fetchApi<TrustPilotReviews>(
    `${process.env.NEXT_PUBLIC_MARKETING_REVIEWS_API_URL}/Reviews/mortgagereviews?${queryParams}`,
    {
      mode: 'cors',
    }
  );
}

export function useGetMortgagesTrustPilotReviews({
  stars,
  pageSize,
  nextPageToken,
  config,
}: UseGetTrustPilotReviews) {
  return useQuery({
    queryKey: [GET_MORTGAGES_TRUST_PILOT_REVIEWS, stars],
    queryFn: () =>
      getMortgagesTrustPilotReviews({ stars, pageSize, nextPageToken }),
    ...config,
  });
}
