import { fetchApi, TUseQueryOptions } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import { TrustPilotScores } from '../reviews.types';
import { GET_MORTGAGES_TRUST_PILOT_SCORES } from '../reviews.constants';

export async function getMortgagesTrustPilotScore() {
  return await fetchApi<TrustPilotScores>(
    `${process.env.NEXT_PUBLIC_MARKETING_REVIEWS_API_URL}/Score/mortgagereviews`,
    {
      mode: 'cors',
    }
  );
}

export function useGetMortgagesTrustPilotScore(
  config: TUseQueryOptions<TrustPilotScores> = {}
) {
  return useQuery({
    queryKey: [GET_MORTGAGES_TRUST_PILOT_SCORES],
    queryFn: () => getMortgagesTrustPilotScore(),
    ...config,
  });
}
