'use client';

import { forwardRef, ForwardedRef } from 'react';

import { cn } from '@pbx/shared/utilities/combine-class-names';

import { TIconProps } from './icon.types';

export const Icon = forwardRef<SVGSVGElement, TIconProps>(
  (
    {
      name,
      width = 32,
      height,
      strokeWidth = 2,
      className,
      ...props
    }: TIconProps,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    /*
     * Check root level README.md for more information about assets
     */
    const basePath = process.env.NEXT_PUBLIC_PB_BASE_PATH || '';
    const spriteUrl = `${basePath}/assets/icons.svg`;

    return (
      <svg
        width={width}
        height={height ?? width}
        {...props}
        strokeWidth={strokeWidth}
        className={cn(className)}
        ref={ref}
      >
        <use href={`${spriteUrl}#${name}`} />
      </svg>
    );
  }
);

Icon.displayName = 'Icon';
